import { Bar } from '@visx/shape'
import React from 'react'

export default function CustomBarCharts({
  data,
  xScale,
  yScale,
  yMax,
  getX,
  getY,
  color,
  onPointerEnter,
  onPointerLeave,
}) {
  return (
    <>
      {data.map((d, i) => {
        const barWidth = xScale.bandwidth() > 100 ? 100 : xScale.bandwidth()
        const barHeight = yMax - yScale(getY(d))
        const barX = xScale(getX(d)) + xScale.bandwidth() / 2 - barWidth / 2
        const barY = yScale(getY(d))
        const barColor = color
        const label = getX(d)
        const value = getY(d)

        return (
          <Bar
            key={`${getX(d)}-${i}`}
            x={barX}
            y={barY}
            width={barWidth}
            height={barHeight}
            fill={barColor}
            data-label={label}
            data-value={value}
            onPointerEnter={onPointerEnter}
            onPointerLeave={onPointerLeave}
          />
        )
      })}
    </>
  )
}
