import React, { useCallback } from 'react'
import { Group } from '@visx/group'
import { scaleBand, scaleLinear } from '@visx/scale'
import { max } from 'd3-array'
import { schemeTableau10 as schemeSet } from 'd3-scale-chromatic'
import 'twin.macro'

import CustomBarCharts from '../CustomBarCharts'
import OverviewAxes from '../OverviewAxes'
import OverviewTooltipContent from '../OverviewTooltipContent'
import useSVGTooltip from '../../connectors/useSvgTooltip'
import { format } from 'd3-format'
import OverviewChartTitle from '../OverviewChartTitle'

const margin = {
  top: 30,
  left: 60,
  right: 40,
  bottom: 20,
}

const getX = (d) => d.date
const getY = (d) => d.y

export default function PrivateCarsChart({
  width,
  height,
  data,
  unit,
  toggle,
  tooltipFormat,
  tickFormat,
  ...rest
}) {
  const xMax = width - margin.left - margin.right
  const yMax = height - margin.top - margin.bottom

  const yByYear = Object.values(
    data.reduce((acc, curr) => {
      const { date, y } = curr

      const total = acc[date]?.y ? acc[date].y + y : y

      return {
        ...acc,
        [date]: {
          ...acc[date],
          date,
          y: total,
        },
      }
    }, {})
  )

  const sortedYears = yByYear.map(getX).sort()
  const xScale = scaleBand({
    domain: sortedYears,
    range: [0, xMax],
    padding: 0.2,
  })

  const yScale = scaleLinear({
    domain: [0, max(yByYear, getY)],
    range: [yMax, 0],
    nice: true,
  })

  const style = { width }

  const {
    containerRef,
    handlePointer,
    TooltipInPortal,
    hideTooltip,
    tooltipLeft,
    tooltipTop,
    tooltipOpen,
    tooltipData,
    tooltipStyles,
  } = useSVGTooltip()

  const handleTooltip = useCallback(
    (e) => {
      const label = e.target.getAttribute('data-label')
      const value = format(tooltipFormat)(e.target.getAttribute('data-value'))

      handlePointer(e, {
        label,
        value,
        unit,
      })
    },
    [handlePointer, unit, tooltipFormat]
  )

  return (
    <div style={style} tw="relative" {...rest}>
      {toggle}
      <svg width={width} height={height} ref={containerRef}>
        <Group top={margin.top} bottom={margin.bottom} left={margin.left}>
          <CustomBarCharts
            data={yByYear}
            xScale={xScale}
            yScale={yScale}
            getX={getX}
            getY={getY}
            yMax={yMax}
            color={schemeSet[0]}
            onPointerEnter={handleTooltip}
            onPointerLeave={hideTooltip}
          />
          <OverviewAxes
            xScale={xScale}
            yScale={yScale}
            top={yMax}
            leftTickFormat={format(tickFormat)}
          />
          <OverviewChartTitle height={yMax} unit={unit} />
        </Group>

        {tooltipOpen && (
          <>
            <TooltipInPortal
              left={tooltipLeft}
              top={tooltipTop}
              style={tooltipStyles}
            >
              <OverviewTooltipContent tooltipData={tooltipData} />
            </TooltipInPortal>
          </>
        )}
      </svg>
    </div>
  )
}
