import React from 'react'

import Layout from '../../components/Layout'
import Cars from '../../components/Cars'

export default function CarsPage() {
  return (
    <Layout>
      <Cars />
    </Layout>
  )
}
