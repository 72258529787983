import React, { useReducer } from 'react'
import { useMeasure } from 'react-use'
import tw, { theme } from 'twin.macro'

import OverviewHeader from '../OverviewHeader'
import CockpitList from '../CockpitList'
import DownloadCard from '../DownloadCard'
import PrivateCarsChart from './PrivateCarsChart'
import BarStackChart from '../BarStackChart'
import ChartToggle from '../ChartToggle'
import useData, { useHelperData } from '../../../src/data/useData'

const CHART_BOTTOM_OFFSET = 90

const EMISSION = 'CO2e g/km'
const KILOMETERS = 'km'
const TRIPS = 'trips'

function getDivisor(yearlyFte, perFte, year) {
  return perFte ? yearlyFte[year] : 1
}

function getCarTypeData(yearlyFte, cars, perFte) {
  const data = cars.map((car) => {
    const year = car.date
    const divisor = getDivisor(yearlyFte, perFte, year)
    const emission = (car[EMISSION] * car[KILOMETERS]) / 1e6 / divisor
    const kilometers = car[KILOMETERS] / divisor

    return {
      date: car.date,
      type: car.type,
      // emission in g/km: convert to total emissions in t
      emission,
      kilometers,
    }
  })

  return data
}

function getPrivateCarsData(cars, toggleY) {
  const data = cars
    .filter((car) => car.type === 'Private')
    .map((d) => ({
      date: d.date,
      y: toggleY ? d[KILOMETERS] : d[TRIPS],
    }))

  return data
}

function getTooltipFormat(kilometersEmission, perFte) {
  if (kilometersEmission) {
    return perFte ? '0.4f' : '0.1f'
  }
  return perFte ? '.1f' : '.3s'
}

const getYProp = (toggleY) => (toggleY ? 'emission' : 'kilometers')

const accessors = {
  x: (d) => d.date,
  z: (d) => d.type,
}

const ACTIONS = {
  KILOMETERS_EMISSION: 'kilometers-emission',
  TRIPS_KILOMETERS: 'trips-kilometers',
  PER_FTE: 'per-fte',
}

function reducer(toggle, action) {
  switch (action.type) {
    case ACTIONS.KILOMETERS_EMISSION:
      return { ...toggle, kilometersEmission: !toggle.kilometersEmission }
    case ACTIONS.TRIPS_KILOMETERS:
      return { ...toggle, tripsKilometers: !toggle.tripsKilometers }
    case ACTIONS.PER_FTE:
      return { ...toggle, perFte: !toggle.perFte }
    default:
      return toggle
  }
}

export default function Cars() {
  const { cars } = useData()
  const { yearlyFte } = useHelperData()
  const [c1Ref, { width: c1Width, height: c1Height }] = useMeasure()
  const [toggle, dispatch] = useReducer(reducer, {
    kilometersEmission: true,
    tripsKilometers: true,
    perFte: true,
  })

  return (
    <div tw="flex-1 lg:grid lg:grid-cols-4 overflow-x-hidden lg:overflow-x-auto">
      <div tw="lg:col-span-3 lg:grid lg:grid-cols-2">
        <CockpitList tw="overflow-hidden">
          <div
            css={[
              `height: calc(100vh - ${theme`spacing.20`});`,
              tw`flex flex-col`,
            ]}
            ref={c1Ref}
          >
            <OverviewHeader
              title={
                <>
                  CO<sub>2</sub>e emissions from business mobility with vehicles
                  (
                  {kilometersEmissionUnit(
                    toggle.kilometersEmission,
                    toggle.perFte
                  )}
                  )
                </>
              }
            />
            <div tw="flex-1 h-0">
              {c1Height && (
                <BarStackChart
                  width={c1Width}
                  height={c1Height - CHART_BOTTOM_OFFSET}
                  data={getCarTypeData(yearlyFte, cars, toggle.perFte)}
                  accessors={accessors}
                  xProp="date"
                  yProp={getYProp(toggle.kilometersEmission)}
                  unit={kilometersEmissionUnit(
                    toggle.kilometersEmission,
                    toggle.perFte
                  )}
                  toggle={
                    <div>
                      <KilometersEmissionToggle
                        selected={toggle.kilometersEmission}
                        onChange={() =>
                          dispatch({ type: ACTIONS.KILOMETERS_EMISSION })
                        }
                      />
                      <PerFteToggle
                        selected={toggle.perFte}
                        onChange={() => dispatch({ type: ACTIONS.PER_FTE })}
                      />
                    </div>
                  }
                  tooltipFormat={getTooltipFormat(
                    toggle.kilometersEmission,
                    toggle.perFte
                  )}
                  tickFormat={toggle.kilometersEmission ? '' : '~s'}
                  tw="px-3 pt-4 pb-3"
                />
              )}
            </div>
          </div>
        </CockpitList>

        <CockpitList tw="overflow-hidden">
          <div
            css={[
              `height: calc(100vh - ${theme`spacing.20`});`,
              tw`flex flex-col`,
            ]}
          >
            <OverviewHeader title="Private car trips" />
            <div tw="flex-1 h-0">
              {c1Height && (
                <PrivateCarsChart
                  width={c1Width}
                  height={c1Height - CHART_BOTTOM_OFFSET}
                  data={getPrivateCarsData(cars, toggle.tripsKilometers)}
                  unit={tripsKilometersUnit(toggle.tripsKilometers)}
                  toggle={
                    <TripsKilometersToggle
                      selected={toggle.tripsKilometers}
                      onChange={() =>
                        dispatch({ type: ACTIONS.TRIPS_KILOMETERS })
                      }
                    />
                  }
                  tooltipFormat={toggle.tripsKilometers ? '.3s' : ''}
                  tickFormat={toggle.tripsKilometers ? '~s' : ''}
                  tw="px-3 pt-4 pb-3"
                />
              )}
            </div>
          </div>
        </CockpitList>
      </div>

      <CockpitList tw="bg-grey-6">
        <OverviewHeader title="EXPLORE" />
        <DownloadCard field="cars" title="Cars" />
        <DownloadCard field="org_fte" title="Department FTE" />
      </CockpitList>
    </div>
  )
}

function kilometersEmissionUnit(toggleY, toggleFte) {
  const main = toggleY ? 'tCO₂e' : 'km'

  return `${main}${toggleFte ? '/FTE p.a.' : ''}`
}

function KilometersEmissionToggle({ selected, onChange }) {
  return (
    <ChartToggle
      selected={selected}
      onChange={onChange}
      rightLabel="Emission"
      leftLabel="Kilometers"
    />
  )
}

function PerFteToggle({ selected, onChange }) {
  return (
    <ChartToggle
      selected={selected}
      onChange={onChange}
      rightLabel="Per FTE"
      leftLabel="Absolute"
      tw="left-52"
    />
  )
}

function tripsKilometersUnit(toggleY) {
  return toggleY ? 'km' : 'trips'
}

function TripsKilometersToggle({ selected, onChange }) {
  return (
    <ChartToggle
      selected={selected}
      onChange={onChange}
      rightLabel="Kilometers"
      leftLabel="Trip Count"
    />
  )
}
